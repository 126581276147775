import React, { useReducer, createContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const timeRangeReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TIME_RANGE':
            return action.payload;
        default:
            return state;
    }
};

export const TimePeriodContext = createContext();

export const TimePeriodProvider = ({ children }) => {
    const { t } = useTranslation();

    const timectx = localStorage?.getItem('timectx');
    let parsedTimectx = {
        date_start: moment()
            .set({ hours: 0, minutes: 0, seconds: 0 })
            .subtract(6, 'days')
            .unix(),
        date_end: moment().set({ hours: 23, minutes: 59, seconds: 59 }).unix(),
        buttonValue: t('header.time_period.last_7_days'),
    };
    if (
        timectx &&
        JSON.parse(timectx)?.date_start &&
        JSON.parse(timectx)?.date_end
    ) {
        if (JSON.parse(timectx)?.index === 'calendar') {
            parsedTimectx = JSON.parse(timectx);
        } else {
            switch (+JSON.parse(timectx)?.index) {
                case 4:
                    parsedTimectx = {
                        ...JSON.parse(timectx),
                        date_start: moment()
                            .set({ hours: 0, minutes: 0, seconds: 0 })
                            .subtract(3, 'months')
                            .unix(),
                        date_end: moment()
                            .set({
                                hours: 23,
                                minutes: 59,
                                seconds: 59,
                            })
                            .unix(),
                    };
                    break;
                case 3:
                    parsedTimectx = {
                        ...JSON.parse(timectx),
                        date_start: moment()
                            .set({ hours: 0, minutes: 0, seconds: 0 })
                            .subtract(2, 'months')
                            .unix(),
                        date_end: moment()
                            .set({
                                hours: 23,
                                minutes: 59,
                                seconds: 59,
                            })
                            .unix(),
                    };
                    break;
                case 2:
                    parsedTimectx = {
                        ...JSON.parse(timectx),
                        date_start: moment()
                            .set({ hours: 0, minutes: 0, seconds: 0 })
                            .subtract(14, 'days')
                            .unix(),
                        date_end: moment()
                            .set({
                                hours: 23,
                                minutes: 59,
                                seconds: 59,
                            })
                            .unix(),
                    };
                    break;
                case 1:
                default:
                    parsedTimectx = {
                        ...JSON.parse(timectx),
                        date_start: moment()
                            .set({ hours: 0, minutes: 0, seconds: 0 })
                            .subtract(7, 'days')
                            .unix(),
                        date_end: moment()
                            .set({
                                hours: 23,
                                minutes: 59,
                                seconds: 59,
                            })
                            .unix(),
                    };
            }
        }
    } else {
        localStorage?.setItem('timectx', JSON.stringify(parsedTimectx));
    }

    const [timePeriodState, timePeriodDispatch] = useReducer(
        timeRangeReducer,
        parsedTimectx
    );
    return (
        <TimePeriodContext.Provider
            value={[timePeriodState, timePeriodDispatch]}
        >
            {children}
        </TimePeriodContext.Provider>
    );
};
